<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :table-headers="tableHeaders"
          :default-table-headers="[]"
          :form-scheme="formScheme"
          :form-default-values="formDefaultValues"
          :resource="resource"
          :item-class-fn="itemClassFn"
          @inputChange="handleChange"
        >
          <template #[`item.email`]="{ item }">
            <div class="pa-2">
              <div class="mb-1">
                {{ item.name }}
              </div>
              <div
                class="grey--text small"
                :title="item.agent"
              >
                {{ item.agent && item.agent.length > 50 ? `${item.agent.substr(0, 50)}...` : (item.agent || '') }}
              </div>
            </div>
          </template>

          <template #[`item.sys`]="{ item }">
            <v-icon :color="item.sys === true ? 'grey' : 'grey'">
              {{ item.sys === true ? 'mdi-check' : 'mdi-minus' }}
            </v-icon>
          </template>

          <template #[`item.status`]="{ item }">
            <v-btn
              icon
              :loading="item.statusLoading"
              @click="checkProxyStatus(item)"
            >
              <v-icon :color="item.status === true ? 'green' : 'red'">
                {{ item.status === true ? 'mdi-check' : 'mdi-sync-alert' }}
              </v-icon>
            </v-btn>
          </template>

          <template #action-buttons="{ item }">
            <v-btn
              v-can="'proxies.create'"
              icon
              :loading="item.cloneLoading"
              @click="cloneProxy(item)"
            >
              <v-icon small>
                mdi-content-copy
              </v-icon>
            </v-btn>
            <v-btn
              v-can="'proxies.auth'"
              icon
              @click="getCredentials(item.id)"
            >
              <v-icon small>
                mdi-key-chain-variant
              </v-icon>
            </v-btn>

            <table-btn
              v-if="(item.domains_providers || []).length > 0"
              :item="item"
              :tooltip-text="$t('Who is using this proxy?')"
              :icon="`eye`"
              :can="'proxies.list'"
              :has="'domains_providers'"
              :fn="getProvidersWithThisProxy"
            />
          </template>
        </table-data>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import countries from '@/countries'
import userAgents from '@/user-agents'

export default {
  name: 'Proxies',
  metaInfo () {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('proxies.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'proxies',
    editedItem: {},
    formDefaultValues: {
      // type: 'socks5',
      agent: ''
    }
  }),
  computed: {
    countriesList() {
      const data = []
      Object.keys(countries).forEach((key) => {
        const country = countries[key]
        data.push({ text: country.name, value: country.code })
      })
      return data
    },
    userAgent() {
      return this.getRandomAgent()
    }
  },
  watch: {
    'editedItem.sys'(val) {
      if (val === true) {
        this.editedItem.affiliate_team_id = null
        this.formScheme.affiliate_team_id.disabled = true
      } else {
        this.formScheme.affiliate_team_id.disabled = false
      }
    }
  },
  async mounted () {
    this.title = this.$t('proxies.title')

    let actionsColWight = 20
    actionsColWight += this.$can('proxies.auth') ? 40 : 0
    actionsColWight += this.$can('proxies.update') ? 40 : 0
    actionsColWight += this.$can('proxies.remove') ? 40 : 0

    this.tableHeaders = [
      {
        text: this.$t('proxies.table.name'),
        align: 'start',
        value: 'email'
      },
      { text: this.$t('proxies.table.host'), value: 'host', width: '150px', align: 'center' },
      { text: this.$t('proxies.table.type'), value: 'type', width: '80px', align: 'center' },
      { text: this.$t('proxies.table.port'), value: 'port', width: '80px', align: 'center' },
      { text: this.$t('proxies.table.country'), value: 'country', width: '80px', align: 'center' },
      { text: this.$t('Systemic'), value: 'sys', width: '80px', align: 'center' },
      { text: this.$t('Status'), value: 'status', width: '80px', align: 'center' },
      {
        text: this.$t('tableData.creationDate'),
        align: 'center',
        value: 'createdAt',
        width: '170px'
      },
      {
        text: this.$t('tableData.actions'),
        align: 'center',
        value: 'actions',
        sortable: false,
        width: `${actionsColWight}px`
      }
    ]

    this.formScheme = {
      name: {
        type: 'text',
        label: this.$t('proxies.tableForm.name'),
        createRules: 'req',
        updateRules: []
      },
      host: {
        type: 'text',
        label: this.$t('proxies.tableForm.host'),
        createRules: 'req',
        updateRules: []
      },
      port: {
        type: 'text',
        label: this.$t('proxies.tableForm.port'),
        createRules: 'req',
        updateRules: []
      },
      type: {
        type: 'select',
        label: this.$t('proxies.tableForm.type'),
        items: ['http', 'https', 'socks4', 'socks5'],
        createRules: 'req',
        updateRules: []
      },
      login: {
        type: 'text',
        label: this.$t('proxies.tableForm.login'),
        createRules: [],
        updateRules: []
      },
      password: {
        type: 'password',
        label: this.$t('proxies.tableForm.password'),
        ref: 'password',
        data: {
          show: false
        },
        createRules: [],
        updateRules: []
      },
      country: {
        type: 'autocomplete',
        label: this.$t('proxies.tableForm.country'),
        items: this.countriesList,
        createRules: [],
        updateRules: []
      },
      agent: {
        type: 'text',
        label: this.$t('proxies.tableForm.agent'),
        createRules: [],
        updateRules: [],
        // value: this.userAgent
        value: 'Affbuddy client API v1'
      },
      sys: {
        type: 'checkbox',
        label: this.$t('Systemic'),
        items: this.countriesList,
        createRules: [],
        updateRules: [],
        md: 2,
        class: 'float-right'
      }
    }
    Object.assign(this.formDefaultValues, { agent: this.getRandomAgent() })

    if (this.$currentUser.role === process.env.VUE_APP_MAIN_ROLE || this.$currentUser.global === true) {
      this.tableHeaders = this.$helper.addToArray(this.tableHeaders, { text: this.$t('proxies.table.team'), value: 'team', width: '200px' }, 1)
      this.formScheme = this.$helper.addToObject(this.formScheme, 'affiliate_team_id', {
        type: 'autocomplete',
        label: this.$t('proxies.tableForm.team'),
        items: await this.$store.dispatch('affiliatesTeams/all'),
        createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: [],
        md: 10,
        clearable: true
      }, 'sys')
    }
  },
  methods: {
    async cloneProxy(item) {
      this.$set(item, 'cloneLoading', true)
      await this.$store.dispatch('proxies/cloneProxy', item.id)
      this.$set(item, 'cloneLoading', false)
    },
    async checkProxyStatus(item) {
      this.$set(item, 'statusLoading', true)
      await this.$store.dispatch('proxies/checkProxyStatus', item.id)
      this.$set(item, 'statusLoading', false)
    },
    async getCredentials (id) {
      const result = await this.$store.dispatch('proxies/getProxyCredentials', { id })
      if (result.id) {
        await this.$store.dispatch('dialog/dialog', {
          show: true,
          title: this.$t('dialog.credentials'),
          contentType: 'code',
          content: JSON.stringify(result, null, 2),
          maxWidth: 500
        })
      }
    },
    async getProvidersWithThisProxy (item) {
      if (item.domains_providers) {
        await this.$store.dispatch('dialog/dialog', {
          show: true,
          title: this.$t('Domains providers'),
          contentType: 'code',
          content: JSON.stringify(item.domains_providers, null, 2),
          maxWidth: 500
        })
      }
    },
    getRandomAgent() {
      return userAgents[Math.floor(Math.random() * userAgents.length)]
    },
    handleChange(item) {
      this.editedItem = item
    },
    itemClassFn(item) {
      let classes

      if (item.status === false) {
        classes += ' red lighten-4 '
      }
      return classes
    }
  }
}
</script>

<style scoped></style>
